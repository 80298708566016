import { on, off } from 'delegated-events'

export default (eventName, selector, listener) => {
  const method = event => {
    const element = event.target.closest(selector)
    off(eventName, selector, method)
    listener(event, element)
  }

  on(eventName, selector, method)
}
