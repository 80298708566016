import bootstrap from './src/bootstrap'
import onOnce from './utils/onOnce'
import lightbox from "./src/lightbox";

export default async () => {
    bootstrap()
    lightbox()

    const galleryElementOnPage = document.querySelector('.gallery')
    galleryElementOnPage && await import(/* webpackChunkName: "gallery" */ './src/gallery.js')

    // Add mobile nav on toggle click
    onOnce('click', '[data-toggle="mobileSidebar"]', async (event) => {
        event.preventDefault()
        const {default: mobileNav, toggle} = await import(/* webpackChunkName: "mobileNav" */ './src/mobileNav.js')
        mobileNav()
        toggle()
    })
}
