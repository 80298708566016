import onOnce from './../utils/onOnce'

export default () => {
  const toggleNames = ['modal', 'collapse', 'tab', 'tooltip']
  const toggleSelectors = toggleNames.map(name => `[data-toggle="${name}"]`).join(',')
  onOnce('click', toggleSelectors, async (event, element) => {
    event.preventDefault()
    await import(/* webpackChunkName: "bootstrap" */ 'bootstrap')
    element.click()
  })
}
