import onOnce from "../utils/onOnce";

export default () => {
    onOnce('click', '[data-fslightbox]', async (event, element) => {
        event.preventDefault()
        await import(/* webpackChunkName: "fslightbox" */ 'fslightbox')
        element.click()
    })
}



